/**=====================
    83. Wishlist CSS start
==========================**/
.wishlist {
    table {
        tr {
            td {
                svg {
                    color: #7b7b7b;
                }
            }
        }
    }
}
/**=====================
    83. Wishlist CSS Ends
==========================**/
