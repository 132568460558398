
.push-notifications-form {
    font-family: 'Poppins',sans-serif !important;
    label {
        font-weight: bolder;
        font-size: larger;
    }
    p{
        font-size: larger;
    }
    .notification-target-individual {
        .selected-user {
            border:1px solid #01b051 !important;
            // color: white;
            border-bottom-right-radius: 20px;
            width :35%;
            margin-left: 20px;
            padding: 5px 0px 5px 20px ;
            p {
                margin: 0 !important;
            }
            svg {
                color: #01b051;
                &:hover{
                    color: black;
                    transition: 0.9s;
                    cursor: pointer;
                }
            }
        }
    }
}