.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    &.infinite {
        animation-iteration-count: infinite;
    }
    &.hinge {
        animation-duration: 2s;
    }
    &.bounceIn {
        animation-duration: 0.75s;
    }
    &.bounceOut {
        animation-duration: 0.75s;
    }
    &.flipOutX {
        animation-duration: 0.75s;
    }
    &.flipOutY {
        animation-duration: 0.75s;
    }
    &.flip {
        backface-visibility: visible;
        animation-name: flip;
    }
}
@keyframes bounce {
    0% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    20% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    53% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    80% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -30px, 0);
    }
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -30px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -15px, 0);
    }
    90% {
        transform: translate3d(0, -4px, 0);
    }
}
.bounce {
    animation-name: bounce;
    transform-origin: center bottom;
}
@keyframes flash {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
}
.flash {
    animation-name: flash;
}
@keyframes pulse {
    0% {
        transform: scaleX(1);
    }
    50% {
        transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
        transform: scaleX(1);
    }
}
.pulse {
    animation-name: pulse;
}
@keyframes rubberBand {
    0% {
        transform: scaleX(1);
    }
    30% {
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        transform: scale3d(1.05, 0.95, 1);
    }
    to {
        transform: scaleX(1);
    }
}
.rubberBand {
    animation-name: rubberBand;
}
@keyframes shake {
    0% {
        transform: translateZ(0);
    }
    to {
        transform: translateZ(0);
    }
    10% {
        transform: translate3d(-10px, 0, 0);
    }
    30% {
        transform: translate3d(-10px, 0, 0);
    }
    50% {
        transform: translate3d(-10px, 0, 0);
    }
    70% {
        transform: translate3d(-10px, 0, 0);
    }
    90% {
        transform: translate3d(-10px, 0, 0);
    }
    20% {
        transform: translate3d(10px, 0, 0);
    }
    40% {
        transform: translate3d(10px, 0, 0);
    }
    60% {
        transform: translate3d(10px, 0, 0);
    }
    80% {
        transform: translate3d(10px, 0, 0);
    }
}
.shake {
    animation-name: shake;
}
@keyframes headShake {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(0);
    }
    0.5% {
        transform: translateX(-6px) rotateY(-9deg);
    }
    1.5% {
        transform: translateX(5px) rotateY(7deg);
    }
    2.5% {
        transform: translateX(-3px) rotateY(-5deg);
    }
    3.5% {
        transform: translateX(2px) rotateY(3deg);
    }
}
.headShake {
    animation-timing-function: ease-in-out;
    animation-name: headShake;
}
@keyframes swing {
    20% {
        transform: rotate(15deg);
    }
    40% {
        transform: rotate(-10deg);
    }
    60% {
        transform: rotate(5deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    to {
        transform: rotate(0deg);
    }
}
.swing {
    transform-origin: top center;
    animation-name: swing;
}
@keyframes tada {
    0% {
        transform: scaleX(1);
    }
    10% {
        transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    }
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    }
    30% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    50% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    70% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    40% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    60% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    to {
        transform: scaleX(1);
    }
}
.tada {
    animation-name: tada;
}
@keyframes wobble {
    0% {
        transform: none;
    }
    15% {
        transform: translate3d(-25%, 0, 0) rotate(-5deg);
    }
    30% {
        transform: translate3d(20%, 0, 0) rotate(3deg);
    }
    45% {
        transform: translate3d(-15%, 0, 0) rotate(-3deg);
    }
    60% {
        transform: translate3d(10%, 0, 0) rotate(2deg);
    }
    75% {
        transform: translate3d(-5%, 0, 0) rotate(-1deg);
    }
    to {
        transform: none;
    }
}
.wobble {
    animation-name: wobble;
}
@keyframes jello {
    0% {
        transform: none;
    }
    to {
        transform: none;
    }
    0.1% {
        transform: none;
    }
    1.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }
    2.3% {
        transform: skewX(6.25deg) skewY(6.25deg);
    }
    3.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }
    4.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }
    5.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
    6.7% {
        transform: skewX(0.390625deg) skewY(0.390625deg);
    }
    7.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}
.jello {
    animation-name: jello;
    transform-origin: center;
}
@keyframes bounceIn {
    0% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 1;
        transform: scaleX(1);
    }
}
.bounceIn {
    animation-name: bounceIn;
}
@keyframes bounceInDown {
    0% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }
    75% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 5px, 0);
    }
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: none;
    }
}
.bounceInDown {
    animation-name: bounceInDown;
}
@keyframes bounceInLeft {
    0% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 0;
        transform: translate3d(-3000px, 0, 0);
    }
    60% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 1;
        transform: translate3d(25px, 0, 0);
    }
    75% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(-10px, 0, 0);
    }
    90% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(5px, 0, 0);
    }
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: none;
    }
}
.bounceInLeft {
    animation-name: bounceInLeft;
}
@keyframes bounceInRight {
    0% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(10px, 0, 0);
    }
    90% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(-5px, 0, 0);
    }
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: none;
    }
}
.bounceInRight {
    animation-name: bounceInRight;
}
@keyframes bounceInUp {
    0% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
    }
    60% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }
    75% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 10px, 0);
    }
    90% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, -5px, 0);
    }
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
}
.bounceInUp {
    animation-name: bounceInUp;
}
@keyframes bounceOut {
    20% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
    50% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    55% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
}
.bounceOut {
    animation-name: bounceOut;
}
@keyframes bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0);
    }
    40% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0);
    }
}
.bounceOutDown {
    animation-name: bounceOutDown;
}
@keyframes bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0);
    }
}
.bounceOutLeft {
    animation-name: bounceOutLeft;
}
@keyframes bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}
.bounceOutRight {
    animation-name: bounceOutRight;
}
@keyframes bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0);
    }
    40% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
}
.bounceOutUp {
    animation-name: bounceOutUp;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.fadeIn {
    animation-name: fadeIn;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInDown {
    animation-name: fadeInDown;
}
@keyframes fadeInDownBig {
    0% {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInDownBig {
    animation-name: fadeInDownBig;
}
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInLeft {
    animation-name: fadeInLeft;
}
@keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInLeftBig {
    animation-name: fadeInLeftBig;
}
@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInRight {
    animation-name: fadeInRight;
}
@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInRightBig {
    animation-name: fadeInRightBig;
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInUp {
    animation-name: fadeInUp;
}
@keyframes fadeInUpBig {
    0% {
        opacity: 0;
        transform: translate3d(0, 2000px, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
.fadeInUpBig {
    animation-name: fadeInUpBig;
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.fadeOut {
    animation-name: fadeOut;
}
@keyframes fadeOutDown {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}
.fadeOutDown {
    animation-name: fadeOutDown;
}
@keyframes fadeOutDownBig {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0);
    }
}
.fadeOutDownBig {
    animation-name: fadeOutDownBig;
}
@keyframes fadeOutLeft {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
}
.fadeOutLeft {
    animation-name: fadeOutLeft;
}
@keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0);
    }
}
.fadeOutLeftBig {
    animation-name: fadeOutLeftBig;
}
@keyframes fadeOutRight {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
}
.fadeOutRight {
    animation-name: fadeOutRight;
}
@keyframes fadeOutRightBig {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}
.fadeOutRightBig {
    animation-name: fadeOutRightBig;
}
@keyframes fadeOutUp {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}
.fadeOutUp {
    animation-name: fadeOutUp;
}
@keyframes fadeOutUpBig {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
}
.fadeOutUpBig {
    animation-name: fadeOutUpBig;
}
@keyframes flip {
    0% {
        transform: perspective(400px) rotateY(-1turn);
        animation-timing-function: ease-out;
    }
    40% {
        transform: perspective(400px) translateZ(150px) rotateY(-190deg);
        animation-timing-function: ease-out;
    }
    50% {
        transform: perspective(400px) translateZ(150px) rotateY(-170deg);
        animation-timing-function: ease-in;
    }
    80% {
        transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
        animation-timing-function: ease-in;
    }
    to {
        transform: perspective(400px);
        animation-timing-function: ease-in;
    }
}
@keyframes flipInX {
    0% {
        transform: perspective(400px) rotateX(90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        transform: perspective(400px) rotateX(-20deg);
        animation-timing-function: ease-in;
    }
    60% {
        transform: perspective(400px) rotateX(10deg);
        opacity: 1;
    }
    80% {
        transform: perspective(400px) rotateX(-5deg);
    }
    to {
        transform: perspective(400px);
    }
}
.flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX;
}
@keyframes flipInY {
    0% {
        transform: perspective(400px) rotateY(90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        transform: perspective(400px) rotateY(-20deg);
        animation-timing-function: ease-in;
    }
    60% {
        transform: perspective(400px) rotateY(10deg);
        opacity: 1;
    }
    80% {
        transform: perspective(400px) rotateY(-5deg);
    }
    to {
        transform: perspective(400px);
    }
}
.flipInY {
    backface-visibility: visible !important;
    animation-name: flipInY;
}
@keyframes flipOutX {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: perspective(400px);
    }
    30% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: perspective(400px) rotateX(-20deg);
        opacity: 1;
    }
    to {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }
}
.flipOutX {
    animation-name: flipOutX;
    //Instead of the line below you could use @include backface-visibility($visibility)
    backface-visibility: visible !important;
}
@keyframes flipOutY {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: perspective(400px);
    }
    30% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: perspective(400px) rotateY(-15deg);
        opacity: 1;
    }
    to {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }
}
.flipOutY {
    //Instead of the line below you could use @include backface-visibility($visibility)
    backface-visibility: visible !important;
    animation-name: flipOutY;
}
@keyframes lightSpeedIn {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0;
    }
    60% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: skewX(20deg);
        opacity: 1;
    }
    80% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: skewX(-5deg);
        opacity: 1;
    }
    to {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: none;
        opacity: 1;
    }
}
.lightSpeedIn {
    animation-name: lightSpeedIn;
    animation-timing-function: ease-out;
}
@keyframes lightSpeedOut {
    0% {
        opacity: 1;
    }
    to {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0;
    }
}
.lightSpeedOut {
    animation-name: lightSpeedOut;
    animation-timing-function: ease-in;
}
@keyframes rotateIn {
    0% {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: center;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(-200deg);
        opacity: 0;
    }
    to {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: center;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: none;
        opacity: 1;
    }
}
.rotateIn {
    animation-name: rotateIn;
}
@keyframes rotateInDownLeft {
    0% {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: left bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(-45deg);
        opacity: 0;
    }
    to {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: left bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: none;
        opacity: 1;
    }
}
.rotateInDownLeft {
    animation-name: rotateInDownLeft;
}
@keyframes rotateInDownRight {
    0% {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: right bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(45deg);
        opacity: 0;
    }
    to {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: right bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: none;
        opacity: 1;
    }
}
.rotateInDownRight {
    animation-name: rotateInDownRight;
}
@keyframes rotateInUpLeft {
    0% {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: left bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(45deg);
        opacity: 0;
    }
    to {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: left bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: none;
        opacity: 1;
    }
}
.rotateInUpLeft {
    animation-name: rotateInUpLeft;
}
@keyframes rotateInUpRight {
    0% {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: right bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(-90deg);
        opacity: 0;
    }
    to {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: right bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: none;
        opacity: 1;
    }
}
.rotateInUpRight {
    animation-name: rotateInUpRight;
}
@keyframes rotateOut {
    0% {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: center;
        opacity: 1;
    }
    to {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: center;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(200deg);
        opacity: 0;
    }
}
.rotateOut {
    animation-name: rotateOut;
}
@keyframes rotateOutDownLeft {
    0% {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: left bottom;
        opacity: 1;
    }
    to {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: left bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(45deg);
        opacity: 0;
    }
}
.rotateOutDownLeft {
    animation-name: rotateOutDownLeft;
}
@keyframes rotateOutDownRight {
    0% {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: right bottom;
        opacity: 1;
    }
    to {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: right bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(-45deg);
        opacity: 0;
    }
}
.rotateOutDownRight {
    animation-name: rotateOutDownRight;
}
@keyframes rotateOutUpLeft {
    0% {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: left bottom;
        opacity: 1;
    }
    to {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: left bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(-45deg);
        opacity: 0;
    }
}
.rotateOutUpLeft {
    animation-name: rotateOutUpLeft;
}
@keyframes rotateOutUpRight {
    0% {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: right bottom;
        opacity: 1;
    }
    to {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: right bottom;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(90deg);
        opacity: 0;
    }
}
.rotateOutUpRight {
    animation-name: rotateOutUpRight;
}
@keyframes hinge {
    0% {
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: top left;
        animation-timing-function: ease-in-out;
    }
    20% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(80deg);
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: top left;
        animation-timing-function: ease-in-out;
    }
    60% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(80deg);
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: top left;
        animation-timing-function: ease-in-out;
    }
    40% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(60deg);
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: top left;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }
    80% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(60deg);
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: top left;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }
    to {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(0, 700px, 0);
        opacity: 0;
    }
}
.hinge {
    animation-name: hinge;
}
@keyframes jackInTheBox {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale(0.1) rotate(30deg);
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: center bottom;
    }
    50% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(-10deg);
    }
    70% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: rotate(3deg);
    }
    to {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale(1);
    }
}
.jackInTheBox {
    animation-name: jackInTheBox;
}
@keyframes rollIn {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(-100%, 0, 0) rotate(-120deg);
    }
    to {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: none;
    }
}
.rollIn {
    animation-name: rollIn;
}
@keyframes rollOut {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(100%, 0, 0) rotate(120deg);
    }
}
.rollOut {
    animation-name: rollOut;
}
@keyframes zoomIn {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}
.zoomIn {
    animation-name: zoomIn;
}
@keyframes zoomInDown {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInDown {
    animation-name: zoomInDown;
}
@keyframes zoomInLeft {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInLeft {
    animation-name: zoomInLeft;
}
@keyframes zoomInRight {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInRight {
    animation-name: zoomInRight;
}
@keyframes zoomInUp {
    0% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomInUp {
    animation-name: zoomInUp;
}
@keyframes zoomOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.3, 0.3, 0.3);
    }
    to {
        opacity: 0;
    }
}
.zoomOut {
    animation-name: zoomOut;
}
@keyframes zoomOutDown {
    40% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    to {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: center bottom;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomOutDown {
    animation-name: zoomOutDown;
}
@keyframes zoomOutLeft {
    40% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    }
    to {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale(0.1) translate3d(-2000px, 0, 0);
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: left center;
    }
}
.zoomOutLeft {
    animation-name: zoomOutLeft;
}
@keyframes zoomOutRight {
    40% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    }
    to {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale(0.1) translate3d(2000px, 0, 0);
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: right center;
    }
}
.zoomOutRight {
    animation-name: zoomOutRight;
}
@keyframes zoomOutUp {
    40% {
        opacity: 1;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    to {
        opacity: 0;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
        //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
        transform-origin: center bottom;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}
.zoomOutUp {
    animation-name: zoomOutUp;
}
@keyframes slideInDown {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateZ(0);
    }
}
.slideInDown {
    animation-name: slideInDown;
}
@keyframes slideInLeft {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateZ(0);
    }
}
.slideInLeft {
    animation-name: slideInLeft;
}
@keyframes slideInRight {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
    to {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateZ(0);
    }
}
.slideInRight {
    animation-name: slideInRight;
}
@keyframes slideInUp {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateZ(0);
    }
}
.slideInUp {
    animation-name: slideInUp;
}
@keyframes slideOutDown {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateZ(0);
    }
    to {
        visibility: hidden;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(0, 100%, 0);
    }
}
.slideOutDown {
    animation-name: slideOutDown;
}
@keyframes slideOutLeft {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateZ(0);
    }
    to {
        visibility: hidden;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(-100%, 0, 0);
    }
}
.slideOutLeft {
    animation-name: slideOutLeft;
}
@keyframes slideOutRight {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateZ(0);
    }
    to {
        visibility: hidden;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(100%, 0, 0);
    }
}
.slideOutRight {
    animation-name: slideOutRight;
}
@keyframes slideOutUp {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translateZ(0);
    }
    to {
        visibility: hidden;
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate3d(0, -100%, 0);
    }
}
.slideOutUp {
    animation-name: slideOutUp;
}
