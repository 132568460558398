.reviewreply {
    .likeicon,
    .commenticon {
        cursor: pointer;
        span {
            font-size: small;
            color: #0000ee;
        }
    }
}
