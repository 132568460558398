// Stacked Icons
// -------------------------

.#{$fa-css-prefix}-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
}
.#{$fa-css-prefix}-stack-1x,
.#{$fa-css-prefix}-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
}
.#{$fa-css-prefix}-stack-1x {
    line-height: inherit;
}
.#{$fa-css-prefix}-stack-2x {
    font-size: 2em;
}
.#{$fa-css-prefix}-inverse {
    color: $fa-inverse;
}
